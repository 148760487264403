$color-primary: #ff9d00;
$color-secondary: #6b7d80;

$theme-colors: (
        "primary": darken(#ff9d00, 5%),
        "danger": #e95208,
        "secondary": #6b7d80
);

$body-color: darken(#6b7d80, 5%);

@import "~bootstrap/scss/bootstrap";
@import "../font/inter";


body, html {
  font-family: 'Inter', sans-serif;
}

.btn-primary {
  color: #fff;
}

.custom-file-label { opacity: 0; }
.custom-file-input { opacity: 1; }

.vich-image {
  a {
    width: 100%;
    display: block;
    background-color: #eaeaea;
    padding: 1em;
    border: 1px solid #e0e0e0;
    img {
      max-width: 100%;
    }
  }
}

.login {
  width: 100vw;
  height: 100vh;
  background-image: url(../images/splash.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  svg {
    height: 100vh;
    flex-basis: 25vh;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0;
    margin: 0;
  }

  .form {
    background-color: $color-secondary;
    color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}